/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// import 'fontsource-lato';
import '@fontsource/lato';
import '@fontsource/lato/100.css';
import '@fontsource/lato/300.css';
import '@fontsource/lato/700.css';
import '@fontsource/lato/900.css';
import './src/styles/global.css';
